$breakpoints: (
  width: (
    dev: $w-dev,
    xxs: $w-xxs,
    xs : $w-xs,
    sm : $w-sm,
    md : $w-md,
    lg : $w-lg,
    xl : $w-xl,
    xxl: $w-xxl,
    fhd: $w-fhd,
  ),
  height: (
    xs : $h-xs
  )
);

@mixin MQ($canvas, $direction: min, $type: width) {
  $break: $canvas;
  $breakSpectrum: map-get($breakpoints, $type);
  @if map-has-key($breakSpectrum, $break) {
    $breakpoint: map-get($breakSpectrum, $break);
    @if $direction == max {
        $breakpoint: $breakpoint - 1px;
    }
    @media (#{$direction}-#{$type}: #{$breakpoint}) {
      @content;
    }
  }
  @else {
    @warn "Not found: '#{$break}' in '$breakpoints'";
  }
}
