@mixin transition-all($args...) {
    @if length($args) >= 1 {
      transition: $args;
    } @else {
      transition: all $transitionDuration $ease-default 0s;
    }
}

@mixin background-cover($repeat: no-repeat, $position: center top, $size: cover, $attachment: scroll) {
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
    background-attachment: $attachment;
}

@mixin position($top: null, $right: null, $bottom: null, $left: null, $all: null, $margin: null, $position: absolute){
  position: $position;
  @if($all != null){
    top: $all;
    right: $all;
    bottom: $all;
    left: $all;
  }
  @else{
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
  margin: $margin;
}

@mixin button($color, $background, $colorHover) {
	color: $color;
	background-color: transparent;
	border-color: $background;
  box-shadow: none;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > .dropdown-toggle {
		color: $colorHover;
		background-color: $background;
	  border-color: $background;
    box-shadow: none;
	}
  &:active:focus {
    color: $colorHover;
		background-color: $background;
	  border-color: $background;
    box-shadow: none;
  }
	&:active,
	&.active,
	.open > .dropdown-toggle {
		background-image: none;
    box-shadow: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
      color: $colorHover;
  		background-color: $background;
  	  border-color: $background;
      box-shadow: none;
		}
	}

  &.btn-filled {
    color: $colorHover;
		background-color: $background;
	  border-color: $background;
    &:hover,
  	&:focus,
  	&.focus,
  	&:active,
  	&.active,
    &:active:focus,
  	.open > .dropdown-toggle,
    &.disabled,
  	&[disabled] {
      color: $color;
    	background-color: darken($background, 7%);
    	border-color: darken($background, 7%);
  	}
  }
}

@mixin body-font($weight: 400) {
  font-family: $font-family-sans;
  font-weight: $weight;
}

@mixin heading-font($weight: 200) {
  font-family: $font-family-serif;
  font-weight: $weight;
}

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(0 0 0px 1px $color-rgba);
  }
}
