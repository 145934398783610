$colors: (
  white: (
    base: #ffffff,
    shade: #eeeeee,
    dark: #cccccc,
  ),
  black: (
    base: #000000,
    light: #9b9b9b,
  ),
	brand: (
		base: #08112f,
    light: #374365,
	),
  brand-beta: (
    base: #ec1f20,
  )
);

@function _color($name, $variant: base, $opacity: 1) {
  $color: null;
  $colorSpectrum: map-get($colors, $name);
  @if $colorSpectrum {
    $color: map-get($colorSpectrum, $variant);
  }
  @if $opacity < 1 {
    $color: rgba($color, $opacity);
  }
  @return $color;
}
