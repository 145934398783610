@import "functions/colors";

// Body
$white: _color(white);
$black: _color(black);
$body-bg: $white;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Colors
$text-color: _color(black, light);

$brand-primary: _color(brand);
$brand-info:    teal;
$brand-success: green;
$brand-warning: khaki;
$brand-danger:  red;

$opacity-base:  0.55;
$opacity-dark:  0.9;
$opacity-light:  0.2;
$opacity-low:  0.05;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans: "Open Sans", sans-serif;
$font-family-serif: "Palanquin", serif;
$font-family-base: $font-family-sans;
$font-weight-thin: 200;
$font-weight-base: 400;
$font-weight-bold: 600;
$font-weight-xbold: 700;
$font-size-base: 14px;
$line-height-base: 1.5;

// Navbar
$navbar-default-bg: _color(white);

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: _color(brand, base, $opacity-light);
$input-border-radius: 7px;
$input-border-focus: _color(brand, base, $opacity-base);
$input-color-placeholder: lighten($text-color, 40%);

$padding-base-vertical: 8.5px;
$padding-base-horizontal: 15px;

// Panels
$panel-default-heading-bg: _color(white);

$margin: 10px;
$padding: 15px;
$tablet-w: 768px;
$tablet-h: 1024px;
$article-container: 940px;
$text-container: 640px;
$small-text-container: 480px;

$smartphone-width: 350px;
$smartphone-min-height: 480px;
$desktop-base-height: 1080px;
$desktop-max-height: 1200px;

$transitionDuration: 190ms;
$transitionDurationSlow: 750ms;
$ease-default: ease-in-out;
$ease-1: cubic-bezier(0.4, 0, 0.2, 1);

$item-img-height-xs: 140px;
$item-img-height-sm: 190px;
$item-img-height-lg: 240px;
$icon-w: 25px;

// Breakpoints
$w-dev: 300px;
$w-xxs: 360px;
$w-xs : 480px;
$w-sm : 768px;
$w-md : 992px;
$w-lg : 1200px;
$w-xl : 1366px;
$w-xxl: 1536px;
$w-fhd: 1920px;
$h-xs : 768px;
