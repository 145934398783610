// Fonts
@import url('https://fonts.googleapis.com/css?family=Palanquin:200,400,500,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i&subset=latin-ext');

@import "variables/variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "main";

@import "modal";
@import "slider";

#intro-storm-group {
  background-position: left top;
  .bg-overlay {
    display: none; }
  .with-spacing-sides {
    background-color: _color(brand);
    @include MQ(xxl) {
      width: 40%;
      background-color: transparent; }
    @include MQ(fhd) {
      width: 33.33%; } }
  @include MQ(xxl) {
    .container-fluid {
      position: relative;
      z-index: 1; }
    .bg-overlay {
      display: block;
      @include position($all: 0);
      background-image: url(../img/grupa-cutOut.svg);
      @include background-cover($position: center center);
      z-index: 0; } } }

#intro-storm-children {
  .children {
    figure {
      position: relative;
      min-height: $smartphone-min-height / 5;
      height: calc(50vh / 5);
      background-color: _color(white, shade);
      cursor: pointer;
      border-bottom: 1px solid $laravel-border-color;
      &:nth-of-type(2n+1) {
        border-right: 1px solid $laravel-border-color; }
      &:nth-last-of-type(2),
      &:last-of-type {
        border-bottom: none; }
      @include MQ(sm) {
        min-height: 140px; }
      @include MQ(lg) {
        height: calc(100vh / 4); }
      @include MQ(xl) {
        padding-left: $padding * 2;
        padding-right: $padding * 2; }
      img {
        @include position($top: 50%, $left: 50%);
        max-height: 30px;
        transform: translate(-50%, -50%);
        @include transition-all;
        &.img-overlay {
          opacity: 0; }
        @include MQ(md) {
          max-height: 40px; }
        @include MQ(xl) {
          max-height: 60px; } }
      &:hover {
        img {
          opacity: 0; }
        img.img-overlay {
          opacity: 1; } } } } }

#intro-storm-numbers {
  .numbers-group {
    > section {
      max-width: 160px;
      margin-left: auto;
      margin-right: auto; }
    @include MQ(sm) {
      margin-top: _spacing(xs); }
    @include MQ(md) {
      > section {
        max-width: 180px; } }
    @include MQ(lg) {
      margin-top: _spacing(lg);
      > section {
        max-width: 200px; } }
    @include MQ(xxl) {
      margin-top: _spacing(lg) * 2;
      > section {
        max-width: 250px; } } } }

.numbers-group {
  background-color: transparent;
  margin-bottom: $padding * 2;
  font-size: 1.4rem;
  &:nth-last-of-type(2),
  &:last-of-type {
    margin-bottom: 0; }
  .number {
    font-size: 3rem;
    @include heading-font(700); }
  div.small,
  div.xsmall {
    margin-top: .6em; }
  @include MQ(lg) {
    margin-bottom: 0;
    font-size: 1.6rem;
    .number {
      font-size: 5rem; }
    hr {
      margin-top: _spacing(xs);
      margin-bottom: _spacing(xs); } }
  @include MQ(xxl) {
    font-size: 1.8rem;
    .number {
      font-size: 6rem; } } }

.services-group {
  figure {
    position: relative;
    min-height: $smartphone-min-height / 4;
    height: calc(50vh / 2);
    &:nth-of-type(2n+1) {
      border-right: 1px solid _color(brand, light); }
    &:nth-of-type(2),
    &:first-of-type {
      border-bottom: 1px solid _color(brand, light); }
    @include MQ(sm) {
      min-height: $smartphone-min-height / 1.5; }
    @include MQ(lg) {
      height: calc(100vh / 2); }
    .label {
      margin-bottom: $padding / 2; }
    a {
      display: flex;
      padding: $padding;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include position($all: 0);
      background-color: _color(brand, light);
      color: _color(white);
      .fa {
        font-size: 5rem;
        color: _color(brand);
        margin-bottom: .4em;
        @include transition-all; }
      &:hover, &:focus, &:active {
        background-color: _color(brand);
        .fa {
          color: _color(brand, light);
          transform: scale(1.4); } } }
    &.bg-cover {
      a {
        background-color: _color(brand, base, $opacity-dark);
        &:hover, &:focus, &:active {
          background-color: _color(brand, base, $opacity-light); } } } } }

.label-group {
  .label {
    margin-right: $margin;
    margin-bottom: $margin; } }

.circle-group {
  &.push-right {
    margin-left: 16.66666667%; }
  .circle {
    position: relative;
    background-color: _color(brand, light);
    border-radius: 50%;
    .circle-content {
      @include position($top: 50%, $left: $margin, $right: $margin);
      transform: translateY(-50%);
      text-align: center;
      span {
        @include heading-font;
        font-size: 1.6rem;
        display: block;
        &.circle-small {
          margin-top: .25em;
          font-size: 1rem; }
        @include MQ(xs) {
          font-size: 2.4rem;
          line-height: 1;
          &.circle-small {
            margin-top: .25em;
            font-size: 1.6rem; } }
        @include MQ(sm) {
          font-size: 3.6rem;
          &.circle-small {
            font-size: 2.4rem; } }
        @include MQ(lg) {
          font-size: 5rem;
          &.circle-small {
            font-size: 4rem; } } } }
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%; }
    &.circle-dark {
      background-color: _color(brand); } }
  footer {
    margin-top: 1em;
    font-size: 1rem;
    text-align: center;
    @include MQ(sm) {
      margin-top: 1.2em;
      font-size: 1.2rem; }
    @include MQ(lg) {
      margin-top: _spacing(xs);
      font-size: 1.4rem; } } }

.members-group {
  .member {
    img {
      display: block;
      margin: $padding auto $margin auto;
      width: _height(xs); } } }

.collage {
  figure {
    width: 100%;
    height: 180px; }
  &.collage {
    figure {
      margin-bottom: $padding; } }
  @include MQ(xs) {
    figure {
      height: 200px; } }
  @include MQ(sm) {
    figure {
      height: 240px; }
    &.collage-3-2-last-xs {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      figure {
        &:nth-of-type(2) {
          width: calc(66.66% - 7.5px);
          margin: 0; }
        &:nth-of-type(3) {
          display: block;
          width: calc(33.34% - 7.5px);
          margin: 0; } } } }
  @include MQ(md) {
    figure {
      height: 300px; }
    &.collage {
      figure {
        &:first-of-type {
          margin-bottom: $padding * 2; } } }
    &.collage-3-2-last-xs {
      figure {
        &:nth-of-type(2) {
          width: calc(66.66% - 15px); }
        &:last-of-type(3) {
          display: block;
          width: calc(33.34% - 15px); } } } } }

.content-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: _spacing(xs);
  border-bottom: 1px solid _color(brand);
  text-align: center;
  a {
    position: relative;
    display: block;
    margin: 0 $padding;
    color: _color(white, dark);
    line-height: 40px;
    &:hover,
    &:focus {
      color: _color(brand); }
    &.active {
      color: _color(brand);
      font-weight: 600;
      cursor: default;
      pointer-events: none;
      &:after {
        display: block;
        content: '';
        @include position($right: 0, $bottom: 0, $left: 0);
        height: 3px;
        background-color: _color(brand); } } } }

#intro-storm-projects {
  figure {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: $smartphone-min-height / 3;
    height: calc(100vh / 3);
    @include MQ(sm) {
      min-height: $tablet-h / 2;
      height: 50vh; }
    @include MQ(lg) {
      height: 100vh; }
    &:before {
      content: '';
      @include position($all: 0);
      background-color: rgba(_color(brand), $opacity-dark);
      opacity: .6;
      @include transition-all;
      z-index: 0; }
    .label, h3 {
      @include transition-all;
      z-index: 1; }
    h3 {
      margin-top: .75em;
      margin-bottom: 0;
      line-height: 1.5;
      span {
        display: block; } }
    a {
      @include position($all: 0);
      z-index: 2; }
    &:hover,
    &:focus {
      &:before {
        opacity: 1; }
      .label {
        transform: translateY(-#{$margin}); }
      h3 {
        color: _color(white);
        transform: scale(1.25); } } } }

#intro-storm-references {
  .references-list {
    margin-top: _height(xs);
    .well {
      margin-bottom: $margin; }
    .row > div {
      @include MQ(md, max) {
        &:nth-of-type(odd) {
          .well {
            margin-right: $margin / 2; } }
        &:nth-of-type(even) {
          .well {
            margin-left: $margin / 2; } } }
      @include MQ(md) {
        .well {
          margin-left: $margin / 2;
          margin-right: $margin / 2; }
        &:nth-of-type(3n+1) {
          .well {
            margin-left: 0; } }
        &:nth-of-type(3n+3) {
          .well {
            margin-right: 0; } }
        &:last-of-type {
          .well {
            margin-left: $margin / 2; } } }
      @include MQ(lg) {
        &:last-of-type {
          .well {
            margin-left: $margin; } }
        .well {
          margin-left: $margin;
          margin-right: $margin;
          margin-bottom: $margin * 2;
          padding: $padding;
          font-size: 1.4rem; } } } } }

#about-mission-vision {
  @include MQ(lg) {
    p {
      padding-left: _spacing(xs);
      padding-right: _spacing(xs); } } }

.has-borders-both {
  border-top: 1px solid _color(black, base, $opacity-low);
  border-bottom: 1px solid _color(black, base, $opacity-low);
  &.borders-white {
    border-color: _color(white) !important; }
  @include MQ(sm) {
    border: none;
    border-left: 1px solid _color(black, base, $opacity-low);
    border-right: 1px solid _color(black, base, $opacity-low); } }

#intro-footer {
  figure {
    position: relative;
    min-height: $smartphone-min-height / 3;
    @include MQ(md) {
      min-height: $smartphone-min-height / 2.5; }
    @include MQ(lg) {
      min-height: $smartphone-min-height / 2; }
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include position($all: 0);
      background-color: transparent; }
    .title {
      position: relative;
      @include transition-all; }
    &:hover,
    &:focus {
      a {
        background-color: rgba(_color(brand), .9); }
      .title {
        color: _color(white);
        transform: scale(1.25); } } } }

.main-footer {
  background-color: _color(brand);
  border-top: _spacing(xs) solid _color(brand, light);
  img {
    max-height: _height(xs); } }

.gallery {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  figure {
    a {
      position: relative;
      display: block;
      overflow: hidden;
      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        content: '\f00e';
        @include position($all: 0);
        background-color: rgba(_color(brand, light), $opacity-dark);
        font-family: FontAwesome;
        font-size: 3.4rem;
        line-height: 1;
        color: _color(white);
        opacity: 0;
        transform: scale(1.5);
        @include transition-all; } }
    img {
      position: relative;
      display: inline-block;
      transform-origin: center center;
      @include transition-all(all $transitionDurationSlow / 2 ease-out); }
    @include MQ(sm, max) {
      margin-bottom: $margin;
      &:nth-of-type(odd) {
        padding-right: $margin / 2; }
      &:nth-of-type(even) {
        padding-left: $margin / 2; } }
    @include MQ(sm) {
      margin-bottom: $padding * 2; }
    &:last-of-type,
    &:nth-last-of-type(2) {
      margin-bottom: 0; }
    @include MQ(dev) {
      a:hover {
        &:after {
          opacity: 1;
          transform: scale(1); }
        img {
          // transform: scale(1.25) rotate(4deg)
          @include transition-all(all $transitionDurationSlow * 2 $ease-default); } }
      &:nth-of-type(even) {
        a:hover {
          img {
 } } } } } }            // transform: scale(1.25) rotate(-4deg)

.news {
  .news-container > .row > div {
    margin-bottom: $padding;
    &:last-of-type {
      margin-bottom: 0; } }
  figure {
    background-color: _color(brand);
    min-height: $item-img-height-xs / 1.2; }
  .news-title {
    margin-top: .5em;
    @include transition-all; }
  article {
    position: relative;
    a {
      @include position($all: 0); }
    &:hover, &:active {
      .news-title {
        color: _color(black); } } }
  &.news-featured {
    figure {
      min-height: $item-img-height-xs; } }
  @include MQ(sm, max) {
    .news-container {
      max-width: $smartphone-min-height; } }
  @include MQ(sm) {
    &.news-featured {
      .news-container > .row > div {
        margin-bottom: 0; }
      figure {
        min-height: $item-img-height-sm; } }
    .news-container > .row > div {
      margin-bottom: $padding * 2; }
    figure {
      min-height: $item-img-height-sm / 1.4; } }
  @include MQ(md) {
    &.news-featured {
      figure {
        min-height: $item-img-height-lg; } }
    figure {
      min-height: $item-img-height-lg / 1.5; } }
  @include MQ(lg) {
    .news-container > .row > div {
      margin-bottom: _height(xs); } } }

.projects {
  .projects-container > .row > div {
    margin-bottom: $padding;
    &:last-of-type {
      margin-bottom: 0; } }
  article {
    position: relative;
    a {
      @include position($all: 0); }
    &:hover, &:active {
      .news-title {
        color: _color(black); } } }
  figure {
    background-color: _color(brand, light);
    min-height: $item-img-height-xs; }
  .news-title {
    margin-top: .5em;
    @include transition-all; }
  @include MQ(sm, max) {
    .projects-container {
      max-width: $smartphone-min-height; } }
  @include MQ(sm) {
    .projects-container > .row > div {
      margin-bottom: $padding * 2; }
    figure {
      min-height: $item-img-height-sm; } }
  @include MQ(md) {
    figure {
      min-height: $item-img-height-lg; } }
  @include MQ(lg) {
    .projects-container > .row > div {
      margin-bottom: _height(xs); } } }

.solutions {
  .solutions-container > .row {
    & > div {
      margin-bottom: $padding * 2;
      &:last-of-type {
        margin-bottom: 0; } } }
  .morph-wrapper {
    display: block;
    height: 100%;
    position: relative;
    background-color: _color(white, shade);
    border: 1px solid _color(black, base, $opacity-low);
    cursor: pointer;
    &:after {
      display: block;
      content: '';
      @include position($all: 0);
      background-color: _color(brand, base, $opacity-light);
      opacity: 0;
      z-index: 1;
      @include transition-all; }
    figure {
      height: 160px; }
    h2 {
      margin: 0;
      padding: $padding * 2;
      // border: 1px solid _color(black, base, $opacity-low)
      border-top: 1px solid _color(black, base, $opacity-low); }
    &:hover, &:active {
      &:after {
        opacity: 1; } } }
  @include MQ(sm, max) {
    .solutions-container {
      max-width: $smartphone-width; } } }

.morph-wrapper {
  @include transition-all;
  &.morphing {
    opacity: 0; } }

.morph-content {
  @include position($top: 0, $left: 0, $position: fixed);
  display: flex;
  width: 0px;
  height: 0px;
  background-color: _color(brand);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  z-index: 99999;
  transition: opacity $transitionDuration;
  @include MQ(sm, max) {
    flex-direction: column; }
  &.morphing {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }
  header, article, .morph-close {
    opacity: 0;
    @include transition-all; }
  header {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: _spacing(xs);
    transform: translateY(3%);
    h2 {
      margin-bottom: 0; }
    @include MQ(sm, max) {
      width: 100%; } }
  article {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: _height(sm);
    overflow-y: auto;
    transform: translateY(-3%);
    @include MQ(sm, max) {
      width: 100%;
      height: 100%;
      padding: $padding; } }
  .morph-close {
    transform: scale(0); }
  &.morphed {
    header, article, .morph-close {
      opacity: 1; }
    header, article {
      transform: translateY(0); }
    .morph-close {
      transform: scale(1); } } }

.morph-close {
  line-height: 1;
  @include position($top: $padding, $right: $padding);
  @include MQ(sm, max) {
    top: 5px;
    right: 5px;
    .svg-icon.svg-icon-close {
      width: 20px;
      height: 20px; }
    .fill-brand .cls-1 {
      fill: _color(white); } } }

.body-overlay {
  @include position($all: 0, $position: fixed);
  background-color: _color(brand, light, $opacity-base);
  visibility: hidden;
  opacity: 0;
  z-index: 99998;
  @include transition-all;
  &.is-visible {
    visibility: visible;
    opacity: 1; } }

.company-card {
  position: relative;
  height: 100%;
  background-color: _color(white, shade);
  padding: _height(xs);
  padding-bottom: _height(xs) * 3;
  header {
    margin-bottom: $padding * 2;
    img {
      max-height: 80px;
      margin-bottom: $padding * 2; } }
  p {
    margin-bottom: 0; }
  .btn {
    @include position($bottom: _height(xs), $left: 50%);
    transform: translateX(-50%); } }

#map {
  height: 200px;
  @include MQ(sm) {
    height: 384px; }
  @include MQ(xxl) {
    height: 500px; } }
