$HEADER: (xs: 50px, sm: 80px, lg: 100px, xl: 120px);
$SPACING: (xs: 30px, sm: 50px, lg: 100px);

@function _height($size) {
  @return map-get($HEADER, $size);
}

@function _spacing($size) {
  @return map-get($SPACING, $size);
}
