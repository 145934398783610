.slider {
  min-height: $smartphone-min-height / 2;
  background-color: _color(brand, light);
  .slides {
    @include position($all: 0);
    margin: 0;
    display: block;
    padding: 0;
    overflow: hidden;
    * {
      user-select: none; }
    .slide-container {
      @include position($all: 0);
      display: block;
      pointer-events: none;
      .slide {
        @include position($all: 0);
        padding: _height(xs);
        padding-right: _spacing(xs);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        opacity: 0;
        transform: scale(1);
        &:before {
          content: '';
          @include position($all: 0);
          background-color: _color(brand, base, $opacity-base);
          z-index: -1; }
        @include transition-all(all $transitionDurationSlow $ease-default);
        @include MQ(sm) {
          padding: _height(sm); }
        @include MQ(lg) {
          padding: _height(lg); }
        @include MQ(xxl) {
          padding: _height(xl); }
        .slide-content {
          padding: $padding * 1.5 $padding;
          padding-right: 0;
          border-left: 2px solid _color(brand-beta);
          .btn.btn-custom {
            background-color: _color(white, base, $opacity-base);
            border-color: _color(brand);
            &:hover, &:focus, &:active {
              background-color: _color(brand);
              color: _color(white); } }
          span {
            display: block; }
          span.label {
            display: inline-block; }
          @include MQ(sm) {
            padding-left: $padding * 2; }
          @include MQ(xl) {
            padding-left: $padding * 2.5; } } } }

    input[type=radio] {
      display: none;
      &:checked + .slide-container {
        pointer-events: auto;
        .slide {
          opacity: 1;
          transform: scale(1);
          @include transition-all(opacity $transitionDurationSlow $ease-default); } } }

    .slider-nav {
      @include position($top: 50%, $left: $margin);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: _height(xs) - $margin * 2;
      height: auto;
      background-color: transparent;
      transform: translateY(-50%);
      label {
        display: block;
        position: relative;
        margin: $margin 0;
        padding: 0;
        width: 22px;
        height: 22px;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        background-color: transparent;
        color: _color(white);
        border: 1px solid _color(white);
        border-radius: 50%;
        cursor: pointer;
        @include transition-all;
        &:hover,
        &:focus {
          background-color: transparent;
          color: _color(brand-beta);
          border-color: _color(brand-beta); }
        i {
          @include position($top: 50%, $left: 50%);
          transform: translate(-50%, -50%); }
        &.next {
          transform: rotate(180deg); } }
      @include MQ(sm) {
        left: 25px; }
      @include MQ(lg) {
        left: 35px;
        label {
          margin: $padding 0;
          width: 26px;
          height: 26px; } }
      @include MQ(xxl) {
        left: 45px;
        label {
          width: 30px;
          height: 30px;
          font-size: 16px; } } }

    .slider-dots {
      display: none;
      @include position($bottom: $padding, $left: $padding);
      height: 12px;
      background-color: transparent;
      @include MQ(sm) {
        bottom: _height(sm) / 2;
        left: _height(sm); }
      @include MQ(lg) {
        bottom: _height(xl);
        left: _height(lg);
        height: 14px; }
      @include MQ(xl) {
        left: _height(xl);
        height: 14px; }
      @include MQ(xxl) {
        left: _height(xl) * 3; }
      .slider-dot {
        display: block;
        margin: 0 3px;
        width: 12px;
        height: 12px;
        background-color: _color(white, dark);
        cursor: pointer;
        border-radius: 50%;
        @include transition-all;
        @include MQ(lg) {
          margin: 0 4px;
          width: 14px;
          height: 14px; }
        &:hover {
          background-color: _color(white); }
        &.active {
          background-color: _color(brand-beta);
          cursor: default;
          pointer-events: none; }
        &:first-of-type {
          margin-left: 0; }
        &:last-of-type {
          margin-right: 0; } } } }

  &.show-dots {
    height: $smartphone-min-height / 1.5;
    @include MQ(lg) {
      height: $smartphone-min-height; }
    @include MQ(xxl) {
      height: $smartphone-min-height * 1.05; }
    .slides {
      .slide-container {
        .slide {
          padding: _height(xs) $padding;
          @include MQ(sm) {
            padding: _height(sm); }
          @include MQ(lg) {
            padding: _height(lg); }
          @include MQ(xl) {
            padding: _height(xl); }
          @include MQ(xxl) {
            padding-left: _height(xl) * 3;
            padding-right: _height(xl) * 3; }
          .slide-content {
            padding: 0;
            border: none;
            @include MQ(sm, max) {
              width: 100%;
              text-align: center; } } } } }
    .slider-dots {
      display: flex;
      align-items: center;
      @include MQ(sm, max) {
        left: 50%;
        transform: translateX(-50%); } } } }
