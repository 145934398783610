.modal-overlay {
  @include position($all: 0, $position: fixed);
  width: 100%;
  height: 100%;
  background-color: _color($white, shade, $opacity-dark);
  visibility: hidden;
  opacity: 0;
  transform: translateX(10%);
  pointer-events: none;
  overflow-y: auto;
  z-index: 9999;
  @include transition(all $transitionDuration $ease-1);
  display: flex;
  align-items: center;
  .overlay-content {
    width: 100%; }
  .overlay-content-inner {
    padding-top: _spacing(xs);
    padding-bottom: _spacing(xs);
    & > img {
      max-height: _height(xs);
      margin-left: auto;
      margin-right: auto; }
    & > * {
      opacity: 0;
      transform: translateY(4px);
      @include transition-all;
      &.is-visible {
        opacity: 1;
        transform: translateY(0); } } }
  @include MQ(md) {
    left: auto;
    width: 50%; }

  .overlay-close {
    @include position($top: $padding, $right: $padding);
    display: flex;
    color: _color(brand);
    font-size: 12px;
    font-style: italic;
    line-height: $icon-w;
    text-decoration: none;
    opacity: 0;
    @include transition-all;
    i {
      font-size: $icon-w;
      margin-left: $margin; } }
  &.is-visible {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
    .overlay-close {
      opacity: 1; } } }
