$h1-base: 3rem;
$h1-step: 0.75;
$h1-font-sizes: (
  null: $h1-base,
  sm  : $h1-base + $h1-step,
  lg  : $h1-base + $h1-step * 2,
  xxl : $h1-base + $h1-step * 4
);

$h2-base: 2.6rem;
$h2-step: 0.5;
$h2-font-sizes: (
  null: $h2-base,
  sm  : $h2-base + $h2-step,
  lg  : $h2-base + $h2-step * 2,
  xxl : $h2-base + $h2-step * 3
);

$h3-base: 2rem;
$h3-step: 0.4;
$h3-font-sizes: (
  null: $h3-base,
  sm  : $h3-base + $h3-step,
  lg  : $h3-base + $h3-step * 2,
  xxl : $h3-base + $h3-step * 2.5
);

$h4-base: 1.6rem;
$h4-step: 0.25;
$h4-font-sizes: (
  null: $h4-base,
  sm  : $h4-base + $h4-step,
  lg  : $h4-base + $h4-step * 2,
  xxl : $h4-base + $h4-step * 2.5
);

$h5-base: 1.4rem;
$h5-step: 0.2;
$h5-font-sizes: (
  null: $h5-base,
  sm  : $h5-base + $h5-step,
  lg  : $h5-base + $h5-step * 2,
  xxl : $h5-base + $h5-step * 2.5
);

$p-font-sizes: (
  null: 1.2rem,
  sm  : 1.4rem,
  xl : 1.5rem
);

$margins-bottom: (
  null: $padding,
  sm  : $margin * 2,
  xl : $padding * 2
);

@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      @include MQ($fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

@mixin margin-bottom($fs-map) {
  @each $fs-breakpoint, $fs-margin in $fs-map {
    @if $fs-breakpoint == null {
      margin-bottom: $fs-margin;
    }
    @else {
      @include MQ($fs-breakpoint) {
        margin-bottom: $fs-margin;
      }
    }
  }
}
