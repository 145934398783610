@import "functions/mixins";
@import "functions/media_query";
@import "functions/fonts";
@import "animations";
@import "functions/metrics";

* {
	outline: none !important;
	backface-visibility: hidden; }

body {
	overflow-x: hidden;
	@include font-size($p-font-sizes); }

.overflow-hidden {
	overflow-y: hidden; }

a {
  color: $brand-primary;
  @include transition-all;
  &:hover,
  &:focus {
    color: $text-color;
    text-decoration: none; } }

h1, .h1 {
	@include font-size($h1-font-sizes); }

h2, .h2 {
	@include font-size($h2-font-sizes); }

h3, .h3 {
	@include font-size($h3-font-sizes); }

h4, .h4 {
	@include font-size($h4-font-sizes); }

h5, .h5 {
	@include font-size($h5-font-sizes); }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	@include heading-font;
	line-height: 1.6;
	margin-top: 0;
	margin-bottom: .5em;
	&.has-shadow {
		text-shadow: 0 4px 8px rgba(_color(black), $opacity-base); }
	&.with-background {
		display: inline-block;
		background-color: transparent;
		padding: 0;
		margin-bottom: 1em;
		span {
			display: block;
			margin-bottom: .4em;
			padding: .4em 1em;
			background-color: rgba(_color(brand, light), $opacity-dark);
			color: _color(white);
			line-height: 1;
			i {
				font-style: normal; }
			&:last-of-type {
				margin-bottom: 0; } }
		&.background-light {
			span {
				background-color: rgba(_color(white, shade), $opacity-dark);
				color: _color(brand); } } }
	&.oval-border-top {
		&:before {
			display: block;
			content: '';
			position: relative;
			margin: 0 auto $padding auto;
			width: _spacing(sm);
			height: $margin;
			background-color: _color(brand);
			border-radius: 50px; } }
	&.with-border {
		&:after {
			display: block;
			content: '';
			margin: $margin * 2 0 $padding * 2 0;
			height: $margin;
			width: $margin * 10;
			background-color: _color(brand, light);
			@include MQ(xl) {
				margin: _spacing(xs) * 1.25 0; }
			@include MQ(xxl) {
				margin: _height(xs) 0; } }
		&.border-gray:after {
			background-color: _color(black, light); }
		&.border-light:after {
			background-color: rgba(_color(black), $opacity-light); }
		&.text-center:after {
			margin-left: auto;
			margin-right: auto; } } }

p, .p {
	@include font-size($p-font-sizes); }

p, .p, ul {
	@include margin-bottom($margins-bottom); }

ul {
	&.list-unstyled {
		li {
			&:before {
				display: inline-block;
				margin-right: 5px;
				content: '\f111';
				color: _color(brand);
				font-family: FontAwesome;
				transform: scale(.3); } } } }

.uppercased {
	text-transform: uppercase; }

.thin {
	font-weight: 200; }

.medium {
	font-weight: 500; }

.strong {
	font-weight: 600; }

.extra-strong {
	font-weight: 700; }

.italic {
	font-style: italic; }

.small-italic {
	font-size: 12px;
	font-style: italic;
	color: rgba(_color(black), .4); }

.small {
	font-size: .75em; }

.xsmall {
	font-size: .66em; }

.big {
	font-size: 1.5em; }

.z-index-start {
	z-index: 1 !important; }

.svg-icon {
	&.svg-icon-close {
		width: $icon-w;
		height: $icon-w; } }

.ml-1 {
	margin-left: 1rem; }

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.main-content {
	flex: 1 1 auto;
	overflow-x: hidden; }

// Navbar
.navbar {
	margin-bottom: 0;
	min-height: _height(xs);
	min-height: 0;
	z-index: 9999;
	backface-visibility: hidden; }

.navbar-default {
	background-color: _color(brand);
	border: none;
	box-shadow: 0 2px 2px -2px transparent;
	@include transition-all;
	&.moving {
		box-shadow: 0 2px 2px -2px _color(brand, light); }
	&.nav-up {
		transform: translateY(-100%); }
	.navbar-header {
		position: relative;
		background-color: _color(brand);
		z-index: 1; }
	.navbar-header,
	.navbar-nav {
		display: flex;
		align-items: center;
		border-top: 1px solid _color(white, base, $opacity-low);
		.navbar-link {
			position: relative;
			padding: 0 $padding;
			color: _color(white, base, $opacity-base);
			&.navbar-language {
				color: _color(white); }
			@include heading-font(400);
			line-height: _height(xs);
			border: none;
			text-transform: none;
			white-space: nowrap;
			&:hover,
			&:focus {
				background-color: _color(brand, light);
				color: _color(white); }
			&.active {
				background-color: transparent;
				color: _color(white);
				font-weight: 700; }
			@include MQ(sm, max) {
				color: _color(brand, light);
				&.navbar-toggle,
				&.navbar-language {
					width: _height(xs);
					height: _height(xs);
					padding: 0;
					color: _color(white);
					&:hover,
					&:focus {
						background-color: rgba(_color(white), $opacity-low); }
					span {
						display: block;
						width: 16px;
						height: 2px;
						background-color: _color(white);
						border-radius: 10px;
						opacity: 1;
						@include position($left: calc(50% - 8px));
						transform: rotate(0deg);
						@include transition-all;
						&:nth-child(1) {
							top: calc(50% - 1px + 5px); }
						&:nth-child(2),
						&:nth-child(3) {
							top: calc(50% - 1px); }
						&:nth-child(4) {
							top: calc(50% - 1px - 5px); } }
					&.navbar-is-open {
						span {
							&:nth-child(1),
							&:nth-child(4) {
								top: calc(50% - 1px);
								left: 46.5%;
								width: 0%; }
							&:nth-child(2) {
								transform: rotate(45deg); }
							&:nth-child(3) {
								transform: rotate(-45deg); } } }
					@include MQ(xxs, max) {
						min-width: 40px;
						padding: 0; } } } } }
	.navbar-nav {
		.navbar-link {
			line-height: _spacing(xs); } }
	.navbar-collapse {
		display: block;
		padding: 0;
		background-color: _color(brand);
		border: none;
		border-top: 1px solid _color(brand, light);
		border-bottom: 1px solid _color(brand, light);
		box-shadow: none;
		overflow-x: auto;
		z-index: 0;
		@include transition-all;
		@include MQ(sm, max) {
			@include position($position: fixed, $top: 0, $bottom: 0, $right: 0, $left: 0);
			padding-top: _height(xs);
			opacity: 0;
			transform: rotate(1deg) translateY(8px) scale(.96);
			transform-origin: center top;
			pointer-events: none;
			max-height: none; }
		&.is-visible {
			opacity: 1;
			transform: rotate(0deg) translateY(0) scale(1);
			pointer-events: auto; }
		.navbar-nav {
			margin: 0;
			li {
				width: auto;
				text-align: center; }
			.navbar-link {
				padding: 0 $margin;
				font-size: 1.4rem;
				line-height: _height(xs); }
			@include MQ(sm, max) {
				flex-direction: column;
				li {
					width: 100%;
					text-align: left;
					span {
						display: block;
						margin-top: 5px;
						font-size: 1.6rem; } }
				.navbar-link {
					padding-top: $margin;
					padding-bottom: $margin;
					line-height: 1; } }
			@include MQ(xxs, max) {
				// li
				// 	min-width: 40px
				// .navbar-link
				// 	padding: 0 $margin / 2
 } } }				// 	font-size: 1.2rem
	@include MQ(sm, max) {
		.container-fluid > .navbar-collapse {
			margin-left: 0;
			margin-right: 0; } }
	@include MQ(sm) {
		> .container-fluid {
			padding-right: 0; }
		.navbar-link i {
			display: none; }
		.navbar-collapse {
			border: none;
			.navbar-nav {
				li {
					width: auto;
					.navbar-link {
						padding: 0 $padding / 2;
						font-size: 1.2rem;
						i {
							display: none; }
						&.navbar-language {
							border-left: 1px solid _color(brand, light); } } } } } }
	@include MQ(md) {
		min-height: _height(sm);
		.navbar-header {
			.navbar-brand {
				line-height: _height(sm);
				img {
					height: 40px; } } }
		.navbar-collapse {
			.navbar-nav li .navbar-link {
				padding: 0 $padding;
				font-size: 1.3rem;
				line-height: _height(sm);
				&.navbar-language img {
					margin-bottom: 1px; } } } }
	@include MQ(lg) {
		.navbar-collapse {
			.navbar-nav li .navbar-link {
				font-size: 1.4rem;
				&.navbar-language {
					padding: 0 _spacing(xs);
					margin-left: _spacing(xs);
					img {
						margin-bottom: 2px; } } } } }
	@include MQ(xl) {
		min-height: _height(lg);
		.navbar-header {
			.navbar-brand {
				line-height: _height(lg);
				margin-left: _height(xl);
				img {
					height: 50px; } } }
		.navbar-collapse {
			.navbar-nav li .navbar-link {
				line-height: _height(lg);
				&.navbar-language {
					padding: 0 _spacing(sm);
					margin-left: _spacing(sm); } } } }
	@include MQ(xxl) {
		.navbar-collapse {
			.navbar-nav li .navbar-link {
				font-size: 1.6rem;
				&.navbar-language img {
						margin-bottom: 3px; } } } } }

.navbar-brand {
	margin: 0;
	height: auto;
	img {
		position: relative;
		top: -2px;
		display: inline-block;
		width: auto;
		height: 30px;
		backface-visibility: hidden;
		@include transition-all; } }

.navbar-language,
.navbar-toggle {
	float: none;
	margin: 0;
	padding: 0 $margin;
	background-color: transparent;
	background-image: none;
	border: none;
	border-radius: 0;
	order: 1;
	@include transition-all; }

.navbar-language {
	margin-left: auto;
	img {
		width: 14px;
		height: auto; } }

.dropdown-menu {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	min-width: 0;
	background-color: _color(brand, light);
	border: none;
	border-radius: 0;
	box-shadow: -4px 4px 8px -5px _color(black);
	opacity: 0;
	visibility: hidden;
	transform: rotate(-1deg) translateY(8px) scale(.96);
	@include transition-all;
	@include MQ(sm, max) {
		width: calc(100% + 30px); }
	@include MQ(lg) {
		width: calc(100% - 30px); }
	@include MQ(xl) {
		width: calc(100% - 50px); }
	> li {
		> a {
			padding: 5px $padding;
			font-size: 1.2rem;
			color: _color(white, base, $opacity-base);
			text-align: right;
			@include MQ(md) {
				padding: $padding / 2 _spacing(xs); }
			@include MQ(xl) {
				font-size: 1.4rem;
				padding: $margin _spacing(sm); }
			&.active {
				color: _color(white);
				font-weight: 600;
				pointer-events: none; }
			&:hover,
			&:focus {
				background-color: _color(brand);
				color: _color(white); } } }
	@include MQ(sm, max) {
		display: flex !important;
		left: -#{$padding};
		right: -#{$padding};
		> li {
			flex-grow: 1;
			flex-basis: 0;
			> a {
				text-align: center;
				padding-top: $margin;
				padding-bottom: $margin; } } } }

.open > .dropdown-menu {
	opacity: 1;
	visibility: visible;
	transform: rotate(0deg) translateY(0px) scale(1); }

.m-t-xs {
	margin-top: $padding;
	@include MQ(xxl) {
		margin-top: $padding * 2; } }
.m-t-sm {
	margin-top: $padding * 2;
	@include MQ(xxl) {
		margin-top: _height(xs); } }
.m-b-xs {
	margin-bottom: $padding;
	@include MQ(xxl) {
		margin-bottom: $padding * 2; } }
.m-b-sm {
	margin-bottom: $padding * 2;
	@include MQ(xxl) {
		margin-bottom: _height(xs); } }
.m-tb-lg {
	margin-top: _height(xs);
	margin-bottom: _height(xs); }

// Colors
.text-white {
  color: _color(white); }

.fill-white {
	fill: _color(white);
	.cls-1 {
		fill: _color(white); } }

.fill-black {
	fill: _color(black);
	.cls-1 {
		fill: _color(black); } }

.fill-brand {
	fill: _color(brand);
	.cls-1 {
		fill: _color(brand); } }

.text-brand {
  color: _color(brand); }

.text-brand-light {
  color: _color(brand, light); }

.text-brand-beta {
  color: _color(brand-beta); }

.text-shaded {
	opacity: $opacity-base; }

.bg-white {
	background-color: _color(white); }

.bg-brand-light {
	background-color: _color(brand, light); }

// Button Styles
.btn-default {
	@include button(_color(white), _color(brand, light), _color(white)); }

.btn-primary {
	@include button(_color(brand), _color(brand), _color(white)); }

.btn {
	border-width: 1px;
	border-style: solid;
	border-radius: 300px;
	padding: $margin $padding * 3;
	font-style: italic;
	@include transition-all(background-color $transitionDuration $ease-default, color $transitionDuration $ease-default, border $transitionDuration $ease-default);
	&:active {
		transform: scale(.975); }
	@include MQ(md) {
		padding: $margin * 1.2 $padding * 3; }
	@include MQ(xxl) {
		padding: $padding $padding * 4;
		font-size: 1.6rem; } }

// Layouts

.block {
	display: block; }

.no-gutters {
	margin-left: 0;
	margin-right: 0;
	& > [class*='col-'] {
	  padding-right: 0;
	  padding-left: 0; } }

.relative {
	position: relative; }

.section {
	padding: _height(xs) 0;
	@include MQ(sm) {
		padding: _height(sm) 0; }
	@include MQ(lg) {
		padding: _height(lg) 0; }
	@include MQ(xxl) {
		padding: _height(xl) 0; }
	&.no-spacing {
    padding: 0; }
	&.no-spacing-top {
    padding-top: 0; }
	&.no-spacing-bottom {
    padding-bottom: 0; }
	&.branded {
    background-color: _color(brand); }
	&.shaded {
    background-color: _color(white, shade); }
	&.inverted {
    background-color: _color(black); }
	&.with-border {
		border-bottom: 1px solid rgba(_color(black), $opacity-low); }
	&.with-gradient {
		position: relative;
		&:before {
			content: '';
			@include position($all: 0);
			background: linear-gradient(to top, _color(brand), transparent);
			z-index: 0; }
		> * {
			position: relative;
			z-index: 1; } }
	&.with-overlay {
		&:before {
			content: '';
			@include position($all: 0);
			background-color: rgba(_color(brand, light), $opacity-dark);
			z-index: 0; }
		> * {
			z-index: 1; } }
	&.hero {
		display: flex;
		padding-top: _height(sm);
		align-items: center;
		min-height: $smartphone-min-height / 2;
		@include MQ(md) {
			padding-top: _height(xl) + _height(sm);
			padding-bottom: _height(xl); }
		@include MQ(xl) {
			min-height: $desktop-max-height / 2; }
		.hero-title {
			margin: 0;
			display: flex;
			flex-direction: column;
			align-items: center; } }
	&.full {
		min-height: $small-text-container;
		height: 100vh; } }

.full-width {
	width: 100%; }

.full-height {
	@include MQ(lg) {
		min-height: $text-container;
		height: 100vh; }
	@include MQ(xxl) {
		min-height: $desktop-base-height; } }

.article-container {
	max-width: $article-container;
	margin-left: auto;
	margin-right: auto; }
.text-container {
	max-width: $text-container;
	margin-left: auto;
	margin-right: auto; }
.small-text-container {
	max-width: $small-text-container;
	margin-left: auto;
	margin-right: auto; }

.with-spacing {
	padding-top: _height(xs);
	padding-bottom: _height(xs);
	@include MQ(lg) {
		padding-top: _height(sm);
		padding-bottom: _height(sm); }
	@include MQ(xxl) {
		padding-top: _height(lg);
		padding-bottom: _height(lg); } }

.with-spacing-sides {
	@include MQ(sm) {
		padding-left: _height(xs);
		padding-right: _height(xs); }
	@include MQ(lg) {
		padding-left: _height(sm);
		padding-right: _height(sm); }
	@include MQ(xxl) {
		padding-left: _height(lg);
		padding-right: _height(lg); } }

.form-group {
	label {
		font-weight: $font-weight-base;
		&:after {
			content: ':'; } } }

.form-control {
	height: auto;
	box-shadow: none;
	@include form-control-focus; }

textarea.form-control {
	resize: none; }

.well {
	margin-bottom: $padding;
	background-color: _color(white, shade);
	border: none;
	box-shadow: none;
	border-radius: 0; }

.label {
	display: inline-block;
	font-size: 1.2rem;
	font-style: italic;
	padding: .75em 2em;
	border-radius: 50px;
	border: 1px solid transparent;
	&.label-long {
		white-space: normal;
		line-height: 1.4; }
	&.label-default {
		background-color: transparent;
		color: _color(black, light);
		border-color: _color(black, light); }
	&.label-brand-beta {
		background-color: _color(brand-beta);
		border-color: _color(brand-beta); }
	&.label-brand-light {
		background-color: _color(brand, light);
		border-color: _color(brand, light); }
	&.label-xs {
		font-size: 1rem;
		padding: .6em 1.4em; }
	@include MQ(sm) {
		&.label-lg {
			font-size: 1.4rem; } } }

.bg-cover {
	@include background-cover; }

.bg-cover-center {
	@include background-cover($position: center center); }

.bg-cover-parallax {
	@include background-cover($attachment: fixed); }

.row.flex {
	&:before, &:after {
		display: none; } }

.flex {
	display: flex;
	&.flex-column {
		flex-direction: column; }
	&.flex-row {
		flex-direction: row; }
	&.align-center {
		align-items: center; }
	&.justify-center {
		justify-content: center; }
	&.space-between {
		justify-content: space-between; }
	&.row-wrap {
		flex-flow: row wrap; } }

@include MQ(sm) {
	.flex-sm {
		display: flex; }
	&.flex-column-sm {
		flex-direction: column; }
	&.flex-row-sm {
		flex-direction: row; }
	&.align-center-sm {
		align-items: center; }
	&.justify-center-sm {
		justify-content: center; }
	&.space-between-sm {
		justify-content: space-between; }
	&.row-wrap-sm {
		flex-flow: row wrap; } }

@include MQ(lg) {
	.flex-lg {
		display: flex; }
	&.flex-column-lg {
		flex-direction: column; }
	&.flex-row-lg {
		flex-direction: row; }
	&.align-center-lg {
		align-items: center; }
	&.justify-center-lg {
		justify-content: center; }
	&.space-between-lg {
		justify-content: space-between; }
	&.row-wrap-lg {
		flex-flow: row wrap; } }

// fix for Safari browser when using flex with row
.row.flex:before {
	display: none !important; }

hr {
	margin-top: _spacing(xs);
	margin-bottom: _spacing(xs);
	border-color: rgba(_color(white), $opacity-light);
	&.small {
		margin-top: $padding;
		margin-bottom: $padding;
		@include MQ(lg) {
			margin-top: _spacing(xs);
			margin-bottom: _spacing(xs); } }
	&.bg-brand-beta {
		border-color: rgba(_color(brand-beta), $opacity-base); } }

::selection {
  color: _color(white);
  text-shadow: none;
  background: _color(brand, light); }
